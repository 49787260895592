.locations-page-map-wrapper {
  > div {
    width: 100%;
    height: 100%;

    > div {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-top: 10px !important;

    > div {
      > div {
        > div {
          width: 95% !important;
        }
      }
    }
  }
}
