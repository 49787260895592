.carousel-root {
   border-radius: 20px;
}
.carousel .control-next.control-arrow:before {
   border-left: 10px solid transparent;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
   border-top: 0px solid transparent;
   border-bottom: 0px solid transparent;
}
.carousel .control-prev.control-arrow:before {
   border-right: 10px solid transparent;
}
.carousel .slide {
   height: 500px;
   width: 100%;
}
.carousel .slide img {
   width: 100%;
   height: 100%;
   border-radius: 15px;
   object-fit: cover;
}
.carousel .thumbs-wrapper {
   text-align: center;
   overflow-x: scroll;
   margin: 20px 0 0 0;
}
.carousel .thumbs-wrapper::-webkit-scrollbar {
   background-color: transparent;
   height: 2px;
}
.carousel .thumb {
   border: none;
}
.carousel .thumb:focus,
.carousel .thumb:hover {
   border: 2px solid #897dfd;
   border-radius: 15px;
}
.carousel .thumb img {
   height: 50px;
   border-radius: 15px;
}
.carousel .thumb.selected {
   border: 1px solid #897dfd;
   width: fit-content;
   border-radius: 15px;
}
.carousel .thumb ul {
   margin-bottom: 0;
}
.carousel.carousel-slider .control-arrow {
   display: none;
}
@media (max-width: 1200px) {
   .carousel .slide img {
      height: 400px;
   }
}
